// "https://party-agency-nodejs.onrender.com/api";

// let BASE_URL = "http://localhost:5000";
// if (window.location.hostname !== 'localhost') {
//   BASE_URL = `https://nodejs.fairy-tale.bg`;
// }

let BASE_URL = `https://nodejs.fairy-tale.bg`;

export { BASE_URL };
